import React, { Component } from 'react'
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CustomLoader from './CustomLoader';
import { FormControlLabel, Checkbox, Menu } from '@mui/material';
import LoginState from './LoginState';
import DataTable from './DataTable';


export default class AppraisalPipeline extends Component {

  constructor(props) {
    super(props);

    this.state = {
      allFiles: [],
      filteredFiles: [],
      errorMsg: '',
      pageReady: false,
      assignee: '',
      address: '',
      status: [],
      hideInactive: false,
    };

    this.getFiles();
  }
  statusOptions = [
    'Submitted',
    'Appraiser Assignment',
    'Assigned',
    'Scheduled',
    'AMC Review',
    'UW Review',
    'Reviewed',
    'Completed',
    'Canceled',
  ];

  async getFiles() {
    var response = axios.get('../getactivefiles', {
      params: {
        pUserName: LoginState.userName,
        pUserRole: LoginState.userRole,
        pPortalRole: LoginState.portalRole,
        pOrgCode: LoginState.orgCode
      }
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData) {
      this.setState({ allFiles: resultData, filteredFiles: resultData, pageReady: true });
    }
    else {
      this.setState({ errorMsg: 'Error: No Data Found!', pageReady: true });
    }
  }

  filterTimeout;

  // handle input change
  handleInput = e => {
    clearTimeout(this.filterTimeout)
    if (!e) return;
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.filterTimeout = setTimeout(() => {
      this.filterFiles();
    }, 500);
  }

  handleCheckbox = e => {
    this.setState({ hideInactive: e.target.checked }, () => {
      this.filterFiles();
    });
  }

  handleMultiSelectChange = e => {
    const { target: { value } } = e;
    let newVal = value;
    if (value.includes('all')) newVal = this.statusOptions;
    console.log(newVal)
    this.setState(prevState => ({
      status: Array.isArray(newVal) ? newVal : prevState.status.concat([newVal])
    }), () => {
      this.filterFiles()
    })
  };

  filterFiles = () => {
    const { allFiles, assignee, address, hideInactive, status } = this.state;
    const filteredFiles = allFiles.filter(file => {
      let isValid = true;
      if (hideInactive && ['Completed', 'Canceled'].includes(file.status)) {
        isValid = false;
      }
      if (assignee && !file.assignee.toLowerCase().includes(assignee.toLowerCase())) {
        isValid = false;
      }
      if (address && !file.address.toLowerCase().includes(address.toLowerCase()) &&
        !file.orderID.toLowerCase().includes(address.toLowerCase())) {
        isValid = false;
      }
      if (status.length > 0 && !status.includes(file.status)) {
        isValid = false;
      }
      return isValid;
    })
    this.setState({ filteredFiles });

  }


  clearFilters = () => {
    this.setState({ assignee: '', address: '', status: [] }, () => this.filterFiles());
  }

  clearField = name => {
    this.setState({ [name]: '' }, () => this.filterFiles());
  }

  render() {
    const { allFiles, filteredFiles, address, assignee, hideInactive, status } = this.state;
    if (!this.state.pageReady) return <CustomLoader>Appraisal Order Pipeline</CustomLoader>
    return (
      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ fontWeight: 'medium', mb: 2, mt: 4, textAlign: 'center' }}>Appraisal Order Pipeline</Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={3}>
            <OutlinedInput
              disabled={allFiles.length < 1}
              name="address"
              value={address}
              size="small"
              placeholder="Search Address / Order ID"
              fullWidth
              onChange={this.handleInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.clearField('address')}
                    edge="end"
                    disabled={!address}
                  >
                    {address ? <ClearIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <OutlinedInput
              disabled={allFiles.length < 1}
              name="assignee"
              value={assignee}
              size="small"
              placeholder="Assignee"
              fullWidth
              onChange={this.handleInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.clearField('assignee')}
                    edge="end"
                    disabled={!assignee}
                  >
                    {assignee ? <ClearIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <FormControl sx={{ mx: 1, width: 150 }} size="small">
              <InputLabel id="status-filter-select">Status</InputLabel>
              <Select
                id="status-filter-select"
                value={status}
                label="Status"
                multiple
                onChange={this.handleMultiSelectChange}
              >
                {this.statusOptions.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                <MenuItem value="all">All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            {(assignee || address || status.length > 0) && <Button color="error" onClick={this.clearFilters}>Clear</Button>}
          </Grid>
          <Grid item>
            <FormControlLabel required control={<Checkbox name="hideInactive" checked={hideInactive} onClick={this.handleCheckbox} value={hideInactive} />} label="Hide Inactive" />
          </Grid>
        </Grid>
        <DataTable files={filteredFiles} />
        {/* <SortableTable data={files} /> */}
      </Container >
    );
  }
}